import React from 'react';
import Navbar from '../../common/Navbar';
import Hero from './Hero';
import Features from './Features';
import Footer from '../../common/Footer';
import AboutMe from './AboutMe';
import ContactUs from './ContactUs';
import Pricing from './Pricing';
import FAQ from './FAQ';
import './Home.css';

const HomePage = () => {

  return (
    <div className="homepage">
      <Hero />
      <section className="about">
        <h2 className="about-title">Transforming web scraping</h2>
        <p className="about-subtitle">Explore how ThreadReaper simplifies data extraction and manipulation.</p>
      </section>
      <Features />
      <Pricing />
      <FAQ />
      <AboutMe />
      <ContactUs />
    </div>
  );
};

export default HomePage;