import React, { useEffect } from 'react';
import './FAQ.css';

const FAQ = () => {
  useEffect(() => {
    const handleScroll = () => {
      const faqItems = document.querySelectorAll('.faq-item');
      faqItems.forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          setTimeout(() => {
            item.classList.add('visible');
          }, index * 200);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What is ThreadReaper and how does it work?",
      answer: "ThreadReaper is a powerful web scraping API that allows you to gather data efficiently from various websites. It offers features like JavaScript rendering, rotating and premium proxies, geotargeting, and more to ensure you get the data you need quickly and reliably."
    },
    {
      question: "How can ThreadReaper benefit my e-commerce business?",
      answer: "ThreadReaper helps e-commerce businesses by enabling price monitoring, competitor analysis, and product data aggregation. This allows you to stay ahead of market trends and optimize your pricing strategy."
    },
    {
      question: "Can ThreadReaper handle JavaScript-heavy websites?",
      answer: "Yes, ThreadReaper supports JavaScript rendering, which means it can scrape data from modern websites that heavily rely on JavaScript for content loading."
    },
    {
      question: "What kind of support does ThreadReaper offer?",
      answer: "ThreadReaper offers priority email support and dedicated account managers for higher-tier plans. We are committed to helping you resolve any issues and optimize your scraping tasks."
    },
    {
      question: "How does ThreadReaper ensure data accuracy and reliability?",
      answer: "ThreadReaper uses rotating and premium proxies to avoid IP bans and ensure continuous access to target websites. Our advanced scraping techniques also handle dynamic content and minimize data errors."
    },
    {
      question: "Is ThreadReaper scalable?",
      answer: "Yes, ThreadReaper is designed to scale with your needs. Whether you need to scrape data for a small project or a large-scale operation, our flexible plans and concurrent threads capability will support your requirements."
    },
    {
      question: "How can data scientists leverage ThreadReaper?",
      answer: "Data scientists can use ThreadReaper to collect large datasets for analysis, machine learning, and AI projects. Our API simplifies data extraction, allowing you to focus on data analysis and insights."
    },
    {
      question: "What makes ThreadReaper different from other web scraping APIs?",
      answer: "ThreadReaper stands out due to its robust features, including geotargeting, JavaScript rendering, and premium proxy support. Our focus on providing reliable and scalable scraping solutions tailored to your specific needs makes us a top choice."
    },
    {
      question: "How do I get started with ThreadReaper?",
      answer: "Getting started with ThreadReaper is easy. Sign up for a free trial to explore our features and see how our API can meet your web scraping needs. For custom solutions, feel free to schedule a free consultation."
    },
  ];

  return (
    <div id="faq" className="faq">
      <h2 className='section-title'>Frequently Asked Questions</h2>
      <p className="section-subtitle">Find answers to common questions about ThreadReaper and how it can help you.</p>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div className={`faq-item ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;