import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/homepage/Home'
import './App.css';
import Layout from './components/common/Layout';
import Documentation from './components/pages/documentation/Documentation';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/documentation" element={<Documentation />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
