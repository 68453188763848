import React from 'react';
import styles from './Navbar.module.css';

const Navbar = () => {
  return (
    <nav id="navbar" className={styles.navbar}>
      <div className={styles.navbarLeft}>
        <a href='/' className={styles.navbarBrand}>ThreadReaper.</a>
      </div>
      <div className={styles.navbarCenter}>
        <a href="/#pricing" className={styles.navLink}>Pricing</a>
        <a href="/#faq" className={styles.navLink}>FAQ</a>
        <a href="/documentation" className={styles.navLink}>Documentation</a>
      </div>
      <div className={styles.navbarRight}>
        <a href="#" className={styles.navLink}>Login</a>
        <a href="#" className={styles.navLink}>Sign Up</a>
      </div>
    </nav>
  );
};

export default Navbar;