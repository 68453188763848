import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us" style={{ textAlign: 'center' }}>
        <h2 class='section-title'>Contact Us</h2>
        <p class='section-subtitle'>Get in touch with us to schedule a free consultation.</p>
        <div class="calendly-inline-widget" data-url="https://calendly.com/admin-threadreaper/30min?primary_color=007bff" style={{minWidth: 320, height: 700}}></div>
    </div>
  );
}

export default ContactUs;