import React from 'react';
import './AboutMe.css';
import creatorImage from '../../../images/creator.jpg';

const AboutMe = () => {
  return (
    <section className="about-me">
      <div className="about-me-content">
        <h2 className="about-me-title">About Me</h2>
        <p className="about-me-subtitle">Meet the Founder of ThreadReaper</p>
        <div className="creator-info">
          <div className="creator-image">
            <img src={creatorImage} alt="Creator" className="image-from-left" />
          </div>
          <div className="creator-description">
            <p className="text-from-right">
              Benjamin Dance is a long-time web scraper with a passion for developing tools that simplify data extraction. 
              He is proficient in C# and Python and has extensive knowledge of various frameworks and languages. 
              Benjamin founded ThreadReaper to provide a powerful yet user-friendly solution for the web scraping community. 
              As the founder, he oversees marketing, technology, and product development at ThreadReaper.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;