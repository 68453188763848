// Pricing.js

import React from 'react';
import './Pricing.css';

// Individual Plan Component
const Plan = ({ name, price, credits, threads, jsRendering, proxies, geotargeting, support, accountManager, actionText, primaryAction }) => {
  return (
    <div className="plan">
      <h3>{name}</h3>
      <p className="price">{price}</p>
      <ul>
        <li>API Credits: {credits}</li>
        <li>Concurrent Threads: {threads}</li>
        <li>JavaScript Rendering: {jsRendering ? 'Yes' : 'No'}</li>
        <li>Rotating & Premium Proxies: {proxies ? 'Yes' : 'No'}</li>
        <li>Geotargeting: {geotargeting ? 'Yes' : 'No'}</li>
        <li>Priority Email Support: {support ? 'Yes' : 'No'}</li>
        <li>Dedicated Account Manager: {accountManager ? 'Yes' : 'No'}</li>
      </ul>
      <button className={primaryAction ? 'primary' : 'secondary'}>{actionText}</button>
    </div>
  );
};

// Pricing Component
const Pricing = () => {
  return (
    <div id="pricing" className="pricing">
      <h2 className="section-title">Our Pricing Plans</h2>
      <p className="section-subtitle">Select the plan that best fits your needs.</p>
      <div className="plans-container">
        <Plan
          name="Garden"
          price="$49/month"
          credits="150,000"
          threads="20"
          jsRendering={true}
          proxies={true}
          geotargeting={true}
          support={false}
          accountManager={false}
          actionText="Start Free Trial"
        />
        <Plan
          name="Orchard"
          price="$99/month"
          credits="1,000,000"
          threads="50"
          jsRendering={true}
          proxies={true}
          geotargeting={true}
          support={true}
          accountManager={false}
          actionText="Start Free Trial"
        />
        <Plan
          name="Vineyard"
          price="$249/month"
          credits="3,000,000"
          threads="100"
          jsRendering={true}
          proxies={true}
          geotargeting={true}
          support={true}
          accountManager={true}
          actionText="Start Free Trial"
        />
        <Plan
          name="Estate"
          price="Custom/month"
          credits="3,000,000+"
          threads="100+"
          jsRendering={true}
          proxies={true}
          geotargeting={true}
          support={true}
          accountManager={true}
          actionText="Schedule Free Consultation"
          primaryAction={true}
        />
      </div>
    </div>
  );
};

export default Pricing;