import React, { useEffect, useState } from 'react';
import './Hero.css';

const Hero = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <section className="hero">
      <div className={`hero-content ${isVisible ? 'slide-in' : ''}`}>
        <div className="hero-text">
          <h1 className="hero-title">Harvesting Data, Powering Insights.</h1>
          <h2 className="hero-subtitle">Effortless Web Scraping with Proxy Rotation, Concurrent Requests, and More Using the Latest Headless Chrome Browsers</h2>
        </div>
        <div className="btn-container">
          <a href="#" className="btn-primary">Start Free Trial</a>
        </div>
      </div>
    </section>
  );
};

export default Hero;