import React from 'react';
import styles from './Documentation.module.css';

const Documentation = () => {
  return (
    <div className={styles.docContainer}>
      <h1 className={styles.title}>ThreadReaper API Documentation</h1>

      <div className={styles.section}>
        <h2 className={styles.subtitle}>Authentication</h2>
        <p className={styles.paragraph}>
          All requests to the ThreadReaper API require authentication using your private API
          key. To obtain your API key, sign up for an account <a href="#">here</a> and confirm
          your email address.
        </p>
      </div>

      <div className={styles.section}>
        <h2 className={styles.subtitle}>General Parameters</h2>
        <ul className={styles.unorderedList}>
          <li>
            <strong>api_key</strong> (required): Your private API key.
          </li>
          <li>
            <strong>url</strong> (required): The full URL of the page to extract data from.
            Ensure URL encoding is applied.
          </li>
          <li>
            <strong>parameters</strong> (optional): Additional parameters specific to the
            request. <strong>(Continued below)</strong>
          </li>
          <li>
            <strong>headers</strong> (optional): Custom headers to include in the request.
          </li>
          <li>
            <strong>data</strong> (optional, only for <strong>SOW</strong>{' '} and <strong>CULTIVATE</strong>): Allows POST or PUT data to an endpoint.
          </li>
        </ul>
      </div>

      <div className={styles.section}>
        <h2 className={styles.subtitle}>Parameter Options</h2>
        <ul className={styles.unorderedList}>
          <li>
            <strong>json_response</strong> (optional, default: False):
            <ul className={styles.unorderedList}>
              <li>
                <strong>True</strong>: Parses HTML content into JSON format.
              </li>
              <li>
                <strong>False</strong>: Returns raw HTML content.
              </li>
            </ul>
          </li>
          <li>
            <strong>country_code</strong> (optional, default: US): Uses ISO two-digit country
            codes to specify the request location.
          </li>
          <li>
            <strong>device_type</strong> (optional, default: desktop): Specifies the device type making the
            request (desktop or mobile).
          </li>
          <li>
            <strong>render</strong> (optional, default: True):
            <ul className={styles.unorderedList}>
              <li>
                <strong>True</strong>: Renders JavaScript on the page (5 credits per request).
              </li>
              <li>
                <strong>False</strong>: Does not render JavaScript (1 credit per request).
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className={styles.section}>
        <h2 className={styles.subtitle}>Example Usage</h2>
        <pre className={styles.pre}>
          <code className={styles.code}>
          {`POST /sow HTTP/1.1
Host: threadreaper.com
Content-Type: application/json
Authorization: Bearer YOUR_API_KEY

{
  "url": "https://example.com",
  "json_response": true,
  "country_code": "GB",
  "device_type": "desktop",
  "render": true,
  "headers": {
    "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36"
  },
  "data": {
    "key": "value"
  }
}`}
</code>
</pre>
</div>
<div className={styles.sectionNotes}>
       <h2 className={styles.subtitle}>Notes</h2>
       <ul className={styles.unorderedList}>
         <li>Ensure all URLs are properly encoded.</li>
         <li>Requests that render JavaScript consume 5 credits per request.</li>
         <li>Consult your programming language documentation for URL encoding functions.</li>
       </ul>
     </div>
   </div>
  );
};

export default Documentation;