import React from 'react';
import './Features.css'; // Import features.css here

// Import your images
import reapImage from '../../../images/reap.jpg';
import sowImage from '../../../images/sow.jpg';
import cultivateImage from '../../../images/cultivate.jpg';

const Features = () => {
  return (
    <div className="features">
      <h2 className="section-title">Key Features</h2>
      <p className="section-subtitle">Discover the powerful features that make ThreadReaper your ultimate web scraping tool.</p>
      <div className="features-grid">
        <div className="feature-item">
          <img src={reapImage} alt="Reap Image" className="feature-image" />
          <h3 className="feature-name">Reap (GET)</h3>
          <p className="feature-description">
            Allows users to GET data from a URL with a rotating proxy and parse out that data as needed with filters. Supports asynchronous loading with headless browser with the latest Chrome version.
          </p>
        </div>
        <div className="feature-item">
          <img src={sowImage} alt="Sow Image" className="feature-image" />
          <h3 className="feature-name">Sow (POST)</h3>
          <p className="feature-description">
            Allows users to POST data to a URL with a rotating proxy and headless Chrome browser, and retrieve and parse the data that comes back.
          </p>
        </div>
        <div className="feature-item">
          <img src={cultivateImage} alt="Cultivate Image" className="feature-image" />
          <h3 className="feature-name">Cultivate (PUT)</h3>
          <p className="feature-description">
            Allows users to PUT data to a URL with a rotating proxy and headless Chrome browser, and retrieve and parse the data that comes back.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;